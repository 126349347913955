import {useState, useEffect, useContext} from 'react';
import Context from '../Context';
import VehiclesModal from './VehiclesModal';
import TypeOfWahses from './TypeOfWahses';
import OverlaySpinner from '../OverlaySpinner';

const Customer = () => {
	const [cus, setCus] = useState();
	const [cusName, setCusName] = useState();
	const [cusLastName, setCusLastName] = useState();
	const [cars, setCars] = useState();
	const [chosenCar, setChosenCar] = useState();
	const [showCarsModal, setShowCarsModal] = useState(false);
	const [showWashPoints, setShowWashPoint] = useState(false);
	const [load, setLoad] = useState(false);

	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
	const getCus = async() => {
		setLoad(true);
		const res = await fetch(`https://infinity.te-amo.co.za/api/customers/get?userId=${userId}`, {
			  method: 'GET',
	          headers: {
	                  'Content-Type': 'application/json',
	                },
		});
		const data = await res.json();
		setLoad(false);
		setCus(data);
	}

	useEffect(() => {
	  getCus();
	}, []);

	const getCars = async(e) => {

		console.log(e);
		const res = await fetch(`https://infinity.te-amo.co.za/api/${e}/vehicles`);
		const data = await res.json();
		setCars(data);
		console.log(cars);
		setShowCarsModal(true);
	}

	return(
		<>{load?
			<OverlaySpinner />
			:
			''
		  }
		{showCarsModal?
		<VehiclesModal cars={cars} cusName={cusName} cusLastName={cusLastName} setShowCarsModal={setShowCarsModal} setShowWashPoint={setShowWashPoint} setChosenCar={setChosenCar} />:(showWashPoints?<TypeOfWahses setShowCarsModal={setShowCarsModal} setShowWashPoint={setShowWashPoint} chosenCar={chosenCar} />:'' )}
			<div class='card-table'>
				<table class="table">
				  <thead>
				    <tr>
				      <th scope="col">#</th>
				      <th scope="col">First</th>
				      <th scope="col">Last</th>
				      <th scope="col">Total points</th>
				      <th scope="col">#Vehicles</th>
				    </tr>
				  </thead>
				  <tbody>
				  {cus?.map((cus)=> (
				  		<tr>
					      <th scope="row">#</th>
					      <td>{cus.customer.Name}</td>
					      <td>{cus.customer.Last_name}</td>
					      <td>{cus.points}</td>
					      <td><button className="btn btn-sm btn-danger" onClick={()=>{getCars(cus.customer.id); setCusName(cus.customer.Name); setCusLastName(cus.customer.Last_name);}}>View</button></td>
					    </tr>
				  	))
				  }
				  </tbody>
				</table>
			</div>
		</>
	)
}

export default Customer;