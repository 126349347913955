import Context from '../Context';
import {useState, useContext} from 'react';
import {Link} from 'react-router-dom';
import OverlaySpinner from '../OverlaySpinner';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
	const [thiseEmail, setThisEmail] = useState();
	const [pass, setPass] = useState();
	const [loader, setLoader] = useState(false);

	const subLogin = async(e) => {
		e.preventDefault();

		setLoader(true);
		const res = await fetch('https://infinity.te-amo.co.za/api/login', {

          method: 'POST',
          headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({'email': thiseEmail, 'password': pass}),

        });

		const data = await res.json();

		if(data.message == 'bad creds')
		{
			setLoader(false);
        	const incorrect = document.getElementById('incorrect');
        	incorrect.textContent = 'incorrect email or password';
			incorrect.style.color = 'red';
		}
		else{
			setLoader(false);
			setUserId(data.user.id);
			setName(data.user.name);
			setEmail(data.user.email);
			setToken(data.token);
			setLogedIn(true);
			localStorage.setItem("getUserId", JSON.stringify(data.user.id));
			localStorage.setItem("getUserName", JSON.stringify(data.user.name));
			localStorage.setItem("getUserEmail", JSON.stringify(data.user.email));
			localStorage.setItem("getUserToken", JSON.stringify(data.token));
			localStorage.setItem("logedIn2", JSON.stringify(true));
		}

	}

	return(
		<>{loader?
              <OverlaySpinner />
              :
              ''
            }
		<div class='auth-form'>
			<small id="incorrect"></small>
			<form onSubmit={(e)=>subLogin(e)} >
			  <div class="form-group">
			    <label for="exampleInputEmail1">Email address</label>
			    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={thiseEmail} onChange={(e)=>setThisEmail(e.target.value)} placeholder="Enter email" required />
			  </div><br />
			  <div class="form-group">
			    <label for="exampleInputPassword1">Password</label>
			    <input type="password" class="form-control" id="exampleInputPassword1" value={pass} onChange={(e)=>setPass(e.target.value)} placeholder="Password" required />
			  </div><br />
			
			  <button type="submit" class="btn btn-dark">Submit</button><br />
			  <small>Don't have an account?<Link to='/register'>Register</Link></small>
			</form>
		</div>
		</>
	)
}

export default Login;