import {useState, useContext} from 'react';
import Context from './Context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Modal = ({open, setOpen}) => {
	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
	const [CusName, setCusName] = useState();
	const [lname, setLname] = useState();

	const addCus = async(e) => {
		e.preventDefault();
		const res = await toast.promise(
		    fetch("https://infinity.te-amo.co.za/api/customers/add", {
		    	  method: 'POST',
		          headers: {
		                  'Content-Type': 'application/json',
		                },
		                body: JSON.stringify({'name': CusName, 'lname': lname, 'userId': JSON.parse(userId)}),
			    }),
		    {
		      pending: 'Adding customer',
		      success: 'Customer added 👌',
		      error: 'Faild to add customer 🤯'
		    }
		);

		const data = await res.json();

		if(data.message == 'customer created')
		{
			setName('');
			setLname('');
			setOpen(false);
		}
	}

	if(open)
	{
		return(
			<div className="myOverlay">
				<div className="myModal">
					<form onSubmit={(e)=>addCus(e)}><b style={{float: 'right', 'cursor': 'pointer'}} onClick={()=>setOpen(false)}>X</b><br />
					  <div class="form-group">
					    <label for="exampleInputEmail1">First name</label>
					    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={CusName} onChange={(e)=>setCusName(e.target.value)} placeholder="Enter name" required />
					  </div><br />
					  <div class="form-group">
					    <label for="exampleInputPassword1">Last name</label>
					    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Last name" value={lname} onChange={(e)=>setLname(e.target.value)} required />
					  </div><br />
					  <button type="submit" class="btn btn-dark">Submit</button>
					</form>
				</div>
			</div>
		)
	}
	
}

export default Modal;