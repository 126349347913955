import {useState, useContext} from 'react';
import Context from './Context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddVehicle = ({open, setOpen}) => {
		const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
		const [make, setMake] = useState();
		const [model, setModel] = useState();
		const [reg, setReg] = useState();
		const [chosen, setChosen] = useState();
		const [customers, setCustomers] = useState();

		const getCus = async() => 
		{
			const res = await fetch(`https://infinity.te-amo.co.za/api/customers/get?userId=${userId}`);
			const data = await res.json();

			//console.log(data);
			setCustomers(data);
		}

		const addVehicleAction = async(e) => {
			e.preventDefault();

			const res = await toast.promise(
			    fetch("https://infinity.te-amo.co.za/api/add/vehicles", {
			    	  method: 'POST',
			          headers: {
			                  'Content-Type': 'application/json',
			                },
			                body: JSON.stringify({'make': make, 'model': model, 'registration': reg, 'userId': chosen}),
				    }),
			    {
			      pending: 'Adding vehicle',
			      success: 'Vehicle added 👌',
			      error: 'Faild to add vehicle 🤯'
			    }
			);

			const data = await res.json();

			if(data.vehicle_id)
			{
				setMake('');
				setModel('');
				setReg('');
				setOpen(false);
			}
			console.log(data);

		}

		if(open)
		{
			return(
				<div className="myOverlay">
					<div className="myModal2">
						<form onSubmit={(e)=>addVehicleAction(e)}><b style={{float: 'right', 'cursor': 'pointer'}} onClick={()=>setOpen(false)}>X</b><br />
						  <select class="form-select" aria-label="Default select example" onFocus={()=>getCus()} onChange={(e)=>setChosen(e.target.value)}>
							  <option selected hidden>Choose customer</option>
							  {
							    	customers?.map((customer) => <option value={customer.customer.id}>{customer.customer.Name}</option>)
							  }
						  </select><br />
						  <div class="form-group">
						    <label for="exampleInputEmail1">Make</label>
						    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={make} onChange={(e)=>setMake(e.target.value)} placeholder="Vehicle make" required />
						  </div><br />
						  <div class="form-group">
						    <label for="exampleInputPassword1">Model</label>
						    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Vehicle model" value={model} onChange={(e)=>setModel(e.target.value)} required />
						  </div><br />
						  <div class="form-group">
						    <label for="exampleInputPassword1">Registration</label>
						    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Vehicle registration" value={reg} onChange={(e)=>setReg(e.target.value)} required />
						  </div><br />
						  <button type="submit" class="btn btn-dark">Submit</button>
						</form>
					</div>
				</div>
			)
		}
}

export default AddVehicle;