import {useState, useEffect, useContext} from 'react';
import Context from '../Context';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RewardModal = ({showAddRewards, setShowAddRewards}) => {
	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
	const [points, setPoints] = useState();
	const [prize, setPrize] = useState();

	const addReward = async(e) => {
		e.preventDefault();

		const res = await fetch('https://infinity.te-amo.co.za/api/rewards/add', {

          method: 'POST',
          headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({'points': points, 'prize': prize, 'userId': userId}),
        });

        const data = await res.json();

        if(data.message == 'rewards added')
        {
        	setPoints('');
        	setPrize('');
        	setShowAddRewards(false);
        	toast.success('Reward was added.', {
		          position: "top-right",
		          autoClose: 5000,
		          hideProgressBar: false,
		          closeOnClick: true,
		          pauseOnHover: true,
		          draggable: true,
		          progress: undefined,
		          theme: "dark",
		        });
        }

        console.log(data);
	}

	if(showAddRewards)
	return(
		<div className="myOverlay">
			<div className="myModal2"><b style={{float: 'right', 'cursor': 'pointer'}} onClick={()=>setShowAddRewards(false)}>X</b><br />
				<form onSubmit={(e)=>addReward(e)}>
				  <div class="form-group">
				    <label for="exampleInputEmail1">Points</label>
				    <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Points" value={points} onChange={(e)=>setPoints(e.target.value)} />
				  </div><br />
				  <div class="form-group">
				    <label for="exampleInputPassword1">Prize</label>
				    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Prize" value={prize} onChange={(e)=>setPrize(e.target.value)} />
				  </div><br />
				  <button type="submit" class="btn btn-dark">Submit</button>
				</form>
			</div>
		</div>
	)
	else
		return(
			<>
			</>
		)
}

export default RewardModal;