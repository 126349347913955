import { usePDF } from 'react-to-pdf';
import generatePDF from 'react-to-pdf';
import {useState, useEffect, useRef, useContext} from 'react';
import Context from '../Context';
import ParamObject from '../paramObject';
import Logo from '../Untitled-1.png';

const VehiclesModal = ({cars, cusName, cusLastName, setShowCarsModal, setShowWashPoint, setChosenCar}) => {
	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
	const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
	//const targetRef = useRef();
	const [dont, setDont] = useState(false);

	const test = (thisCars) => {
		console.log(thisCars);
		let total = 0;
		const entry = thisCars.map((car, index) =>([
		            index,
		            car.make,
		            car.model,
		            car.registration,
		            car.points,
		        ]));

		const totalPoints = thisCars.forEach((car) => {
			total = parseInt(total) + parseInt(car.points);
		});

		entry.push(['Total', '', '', '', total]);

		const props = {
		    outputType: jsPDFInvoiceTemplate.OutputType.Save,
		    returnJsPDFDocObject: true,
		    fileName: "Invoice 2021",
		    orientationLandscape: false,
		    compress: true,
		    logo: {
		        src: Logo,
		        type: 'PNG', //optional, when src= data:uri (nodejs case)
		        width: 53.33, //aspect ratio = width/height
		        height: 26.66,
		        margin: {
		            top: 0, //negative or positive num, from the current position
		            left: 0 //negative or positive num, from the current position
		        }
		    },
		    stamp: {
		        inAllPages: true, //by default = false, just in the last page
		        src: "https://raw.githubusercontent.com/edisonneza/jspdf-invoice-template/demo/images/qr_code.jpg",
		        type: 'JPG', //optional, when src= data:uri (nodejs case)
		        width: 20, //aspect ratio = width/height
		        height: 20,
		        margin: {
		            top: 0, //negative or positive num, from the current position
		            left: 0 //negative or positive num, from the current position
		        }
		    },
		    business: {
		        name: name,
		        /*address: "Albania, Tirane ish-Dogana, Durres 2001",
		        phone: "(+355) 069 11 11 111",
		        email: "email@example.com",
		        email_1: "info@example.al",
		        website: "www.example.al",*/
		    },
		    contact: {
		        label: "Points document issued for:",
		        name: `${cusName} ${cusLastName}`,
		        /*address: "Albania, Tirane, Astir",
		        phone: "(+355) 069 22 22 222",
		        email: "client@website.al",
		        otherInfo: "www.website.al",*/
		    },
		    invoice: {
		        label: "Points document: ",
		        //num: 19,
		        invDate: "Date: 01/01/2021 18:12",
		        headerBorder: false,
		        tableBodyBorder: false,
		        header: [
		          {
		            title: "#", 
		            style: { 
		              width: 10 
		            } 
		          }, 
		          { 
		            title: "Make",
		            style: {
		              width: 30
		            } 
		          }, 
		          { 
		            title: "Model",
		            style: {
		              width: 80
		            } 
		          }, 
		          { title: "Registration"},
		          { title: "Vehicle points"}
		        ],
		        table: Array.from(entry),
		        additionalRows: [{
		            col1: 'Total:',
		            col2: '145,250.50',
		            col3: 'ALL',
		            style: {
		                fontSize: 14 //optional, default 12
		            }
		        },
		        {
		            col1: 'VAT:',
		            col2: '20',
		            col3: '%',
		            style: {
		                fontSize: 10 //optional, default 12
		            }
		        },
		        {
		            col1: 'SubTotal:',
		            col2: '116,199.90',
		            col3: 'ALL',
		            style: {
		                fontSize: 10 //optional, default 12
		            }
		        }],
		        //invDescLabel: "Invoice Note",
		        //invDesc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.",
		    },
		    footer: {
		        text: "The points document is created on a computer and is valid without the signature and stamp.",
		    },
		    pageEnable: true,
		    pageLabel: "Page ",
		};

		var pdfObject = jsPDFInvoiceTemplate.default(props);
	}

	const Downloadable = () => {
		return(
			<table class="table">
			  <thead>
			    <tr>
			      <th scope="col">#</th>
			      <th scope="col">Make</th>
			      <th scope="col">Model</th>
			      <th scope="col">Registration</th>
			      <th scope="col">Vehicle point</th>
			    </tr>
			  </thead>
			  <tbody>
			  	{cars?.map((car, index) => (
			  			<tr>
					      <th scope="row">#</th>
					      <td>{car.make}</td>
					      <td>{car.model}</td>
					      <td>{car.registration}</td>
					      <td>{car.points}</td>
					    </tr>
			  		))
				}
			  </tbody>
			</table>
		)
	}

	return(
		<>
		<div className="myOverlay">
			<div className="myModal2"><button onClick={() => test(cars)} className="btn btn-dark btn-sm">Download PDF</button> <b style={{float: 'right', 'cursor': 'pointer'}} onClick={()=>setShowCarsModal(false)}>X</b><br /><br />
				<table class="table" ref={targetRef}>
				  <thead>
				    <tr>
				      <th scope="col">#</th>
				      <th scope="col">Make</th>
				      <th scope="col">Model</th>
				      <th scope="col">Registration</th>
				      <th scope="col">Vehicle point</th>
				      <th scope="col">Wash car</th>
				    </tr>
				  </thead>
				  <tbody>
				  	{cars?.map((car) => (
				  			<tr>
						      <th scope="row">#</th>
						      <td>{car.make}</td>
						      <td>{car.model}</td>
						      <td>{car.registration}</td>
						      <td>{car.points}</td>
						      <td><button className="btn btn-sm btn-danger" onClick={()=>{setShowCarsModal(false); setShowWashPoint(true); setChosenCar(car);}}>Wash</button></td>
						    </tr>
				  		))
					}
				  </tbody>
				</table>
			</div>
		</div>

		</>
	)
}

export default VehiclesModal;