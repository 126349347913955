import SideBar from './sideBar';
import Header from './header';
import Modal from './modal';
import AddVehicle from './addVehicle';
import MobileBottomBar from './MobileBottomBar';
import { FaHome, FaUserPlus, FaUsers, FaGift, FaChevronUp } from "react-icons/fa";
import {Outlet} from 'react-router-dom';
import {useState} from 'react';

const Root = () => {
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);

	const showExtn = () => {
	  const extn = document.getElementById('extn');
	  const extn2 = document.getElementById('extn2');

	  extn2.style.display = "block";
	  extn.style.display = "block";
	}
	const exitExtn = () => {
	  const extn = document.getElementById('extn');
	  const extn2 = document.getElementById('extn2');
	  extn.style.display = "none";
	  extn2.style.display = "none";
	}
	
	return(

		<div className="App">
	      <div className='row'>
	          <div className='col-md-2'>
	            <SideBar />
	          </div>


	          <div className='col-md-10'>
	          	<AddVehicle open={open2} setOpen={setOpen2} />
	          	<Modal open={open} setOpen={setOpen}/>
	            <Header />

	            <div className='main-sec'>

	            	<Outlet />

	            </div>
	          </div>
	      </div>

	      <div className="myContainer">
          <button className="btn btn-dark fixed-button" onMouseEnter={showExtn} onMouseLeave={exitExtn}>
          	<small className="btn-extn shadow" id="extn" onClick={()=>setOpen(true)}><strong>+Customer</strong></small>
          	<small className="btn-extn2 shadow" id="extn2" onClick={()=>setOpen2(true)}><strong>+Vehicle</strong></small>
            <FaChevronUp />  Add
          </button>
        </div>

      <MobileBottomBar />
	    </div>
	)
}

export default Root;