//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FaHome, FaBriefcase, FaUsers, FaCarSide, FaGift} from 'react-icons/fa';
import {Link, NavLink} from 'react-router-dom';

const MobileBottomBar = () =>
{
	return(
		<nav className="navbar fixed-bottom navbar-dark bg-dark btmNav">
		  <div className="container">
		    <div className="row myMobileNav">
		    	<div className="col-3 text-light text-center">
		    		<NavLink to='/' className="myMobileNavLink">
			    		<FaHome className="icon" /><br />
			    		<small>Dashboard</small>
		    		</NavLink>
		    	</div>

		    	<div className="col-3 text-light text-center">
		    		<NavLink to='/customers' className="myMobileNavLink">
			    		<FaUsers  className="icon" /><br />
			    		<small>Customers</small>
		    		</NavLink>
		    	</div>

		    	<div className="col-3 text-light text-center">
		    		<NavLink to='/vehicles' className="myMobileNavLink">
			    		<FaCarSide className="icon" /><br />
			    		<small>Vehicles</small>
		    		</NavLink>
		    	</div>

		    	<div className="col-3 text-light text-center">
		    		<NavLink to='/rewards' className="myMobileNavLink">
			    		<FaGift className="icon" /><br />
			    		<small>Rewards</small>
		    		</NavLink>
		    	</div>
		    </div>
		  </div>
		</nav>
	);
}

export default MobileBottomBar