import { FaHome, FaUserPlus, FaUsers, FaLongArrowAltLeft } from "react-icons/fa";
import {useState, useEffect} from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TypeOfWahses = ({cars, setShowCarsModal, setShowWashPoint, chosenCar}) => {

	const action = async(e) => {

		const res = await fetch(`https://infinity.te-amo.co.za/api/add/vehicle/points?id=${chosenCar.id}&type=${e}`);

		if(e == 'full')
		{
			toast.success(`${chosenCar.make} ${chosenCar.model} Full wash (10 points)`, {
	          position: "top-right",
	          autoClose: 5000,
	          hideProgressBar: false,
	          closeOnClick: true,
	          pauseOnHover: true,
	          draggable: true,
	          progress: undefined,
	          theme: "dark",
	        });	
		}
		else if(e == 'ext')
		{
			toast.success(`${chosenCar.make} ${chosenCar.model} Full exterior wash (5 points)`, {
	          position: "top-right",
	          autoClose: 5000,
	          hideProgressBar: false,
	          closeOnClick: true,
	          pauseOnHover: true,
	          draggable: true,
	          progress: undefined,
	          theme: "dark",
	        });
		}
		else if(e == 'inter')
		{
			toast.success(`${chosenCar.make} ${chosenCar.model} Full interior wash (5 points)`, {
	          position: "top-right",
	          autoClose: 5000,
	          hideProgressBar: false,
	          closeOnClick: true,
	          pauseOnHover: true,
	          draggable: true,
	          progress: undefined,
	          theme: "dark",
	        });
		}
		else if(e == 'wheels')
		{
			toast.success(`${chosenCar.make} ${chosenCar.model} wheels wash (5 points)`, {
	          position: "top-right",
	          autoClose: 5000,
	          hideProgressBar: false,
	          closeOnClick: true,
	          pauseOnHover: true,
	          draggable: true,
	          progress: undefined,
	          theme: "dark",
	        });
		}
		

        setShowWashPoint(false);
	}

	return(
		<div className="myOverlay">{console.log(chosenCar)}
			<div className="myModal2"><FaLongArrowAltLeft style={{'cursor': 'pointer'}} onClick={()=>{setShowCarsModal(true); setShowWashPoint(false);}} /><b style={{float: 'right', 'cursor': 'pointer'}} onClick={()=>{setShowCarsModal(false); setShowWashPoint(false);}}>X</b><br /><br />
				<table class="table">
				  <thead>
				    <tr>
				      <th scope="col">#</th>
				      <th scope="col">Type</th>
				      <th scope="col">Points</th>
				      <th scope="col">Action</th>
				    </tr>
				  </thead>
				  <tbody>
			  			<tr>
					      <th scope="row">#</th>
					      <td>Full House wash</td>
					      <td>10</td>
					      <td><button className="btn btn-sm btn-danger" onClick={()=>action('full')}>Wash</button></td>
					    </tr>
					    <tr>
					      <th scope="row">#</th>
					      <td>Full interior</td>
					      <td>5</td>
					      <td><button className="btn btn-sm btn-danger" onClick={()=>action('inter')}>Wash</button></td>
					    </tr>
					    <tr>
					      <th scope="row">#</th>
					      <td>Full exterior</td>
					      <td>5</td>
					      <td><button className="btn btn-sm btn-danger" onClick={()=>action('ext')}>Wash</button></td>
					    </tr>
					    <tr>
					      <th scope="row">#</th>
					      <td>Wheels</td>
					      <td>3</td>
					      <td><button className="btn btn-sm btn-danger" onClick={()=>action('wheels')}>Wash</button></td>
					    </tr>
				  </tbody>
				</table>
			</div>
		</div>
	)
}

export default TypeOfWahses;