import { FaHome, FaUserPlus, FaUsers, FaGift, FaCarSide } from "react-icons/fa";
import { IconContext } from "react-icons";
import {useEffect, useContext, useState} from "react";
import Context from '../Context';
const Home = () =>{
  const [cusCount, setCusCount] = useState();
  const [rewardsCount, setRewardsCount] = useState();
  const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);

  const getCusCount = async() => {
    const res = await fetch(`https://infinity.te-amo.co.za/api/customers/count?userId=${userId}`);
    const data = await res.text();
    setCusCount(data);
  }

  const getRewardsCount = async() => {
    const res = await fetch(`https://infinity.te-amo.co.za/api/rewards/get/count?userId=${userId}`);
    const data = await res.text();
    setRewardsCount(data);
  }

  useEffect(() => {
    getCusCount();
    getRewardsCount();
  }, []);

	return(
          <div className='row'>
            <div className='col-md-6'>
              <div className='home-card'>
                <div className='row'>

                  <div className='col-md-1'>
                    <IconContext.Provider value={{ size:'2em' }}>
                      <div>
                        <FaUsers />
                        <h5>{cusCount}</h5>
                      </div>
                    </IconContext.Provider>
                  </div>


                  <div className='col-md-11'>
                    <p>Customers</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='home-card'>
                <div className='row'>
                  <div className='col-md-1'>
                    <IconContext.Provider value={{ size:'2em' }}>
                      <div>
                        <FaGift />
                        <h5>{rewardsCount}</h5>
                      </div>
                    </IconContext.Provider>
                  </div>
                  <div className='col-md-11'>
                    <p>Rewards</p>
                  </div>
                </div>
              </div>
            </div>


            {/*<div className='col-md-6'>
              <div className='home-card'>
                <div className='row'>

                  <div className='col-md-1'>
                    <IconContext.Provider value={{ size:'2em' }}>
                      <div>
                        <FaCarSide />
                        <h5>{cusCount}</h5>
                      </div>
                    </IconContext.Provider>
                  </div>


                  <div className='col-md-11'>
                    <p>Vehicles</p>
                  </div>
                </div>
              </div>
            </div>*/}

          </div>
	)

}

export default Home