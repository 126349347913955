import {useState, useEffect} from 'react';
const CusModal = ({cus, setCusModal}) => {
	return(
		<div className="myOverlay">
			<div className="myModal2"><b style={{float: 'right', 'cursor': 'pointer'}} onClick={()=>setCusModal(false)}>X</b><br /><br />
				<p><b>Name: {cus.Name}</b></p>
				<p><b>Lastname: {cus.Last_name}</b></p>
			</div>
		</div>
	)
}

export default CusModal;