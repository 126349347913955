import { FaHome, FaUserPlus, FaUsers, FaGift, FaCarSide } from "react-icons/fa";
import {NavLink, Link} from 'react-router-dom';
import {useContext} from 'react';
import Context from './Context';
import Logo from './Untitled-1.png';

const SideBar = () => {
	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
	return(
		<>
			<main>
		      <h1 className="visually-hidden">Sidebars examples</h1>

		      <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark sidebars" style={{width: '15%', height: '100vh'}}>
		        <a href="/" style={{textAlign: 'center',  marginLeft: 'auto'}} className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
		          <div className="fs-4 d-flex justify-content-center align-items-center" style={{textAlign: 'center'}}><img src={Logo} className="icone" /></div>
		        </a>
		        <hr />
		        <ul className="nav nav-pills flex-column mb-auto">
		          <li className="nav-item">
		            <NavLink to="/" className="nav-link text-white align-items-left" style={{textAlign: 'left'}}>
		              <FaHome /> <span style={{marginLeft: 10}}>Dashboard</span>
		            </NavLink>
		          </li>
		          {/*<li>
		            <NavLink to="/customers/add" className="nav-link text-white" style={{textAlign: 'left'}}>
		              <FaUserPlus /> <span style={{marginLeft: 10}}>+ Customers</span>
		            </NavLink>
		          </li>*/}
		          <li>
		            <NavLink to="/customers" className="nav-link text-white" style={{textAlign: 'left'}}>
		              <FaUsers /> <span style={{marginLeft: 10}}>Customers</span>
		            </NavLink>
		          </li>
		          <li>
		            <NavLink to="/vehicles" className="nav-link text-white" style={{textAlign: 'left'}}>
		              <FaCarSide /> <span style={{marginLeft: 10}}>Vehicles</span>
		            </NavLink>
		          </li>
		          <li>
		            <NavLink to="/rewards" className="nav-link text-white" style={{textAlign: 'left'}}>
		              <FaGift /> <span style={{marginLeft: 10}}>Rewards</span>
		            </NavLink>
		          </li>
		        </ul>
		        <hr />
		        <div className="dropdown">
		          <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
		            <img src="https://avatar.iran.liara.run/public/boy?username=Ash" alt="" width="32" height="32" className="rounded-circle me-2" />
		            <strong>{name}</strong>
		          </a>
		          <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
		            <li><a className="dropdown-item" href="#">Settings</a></li>
		            <li><a className="dropdown-item" href="#">Profile</a></li>
		            <li><hr className="dropdown-divider" /></li>
		            <li><Link className="dropdown-item" to="/" onClick={()=>{localStorage.setItem("logedIn2", JSON.stringify(false));setLogedIn(false);}}>Sign out</Link></li>
		          </ul>
		        </div>
		      </div>
		    </main>
		</>
	)

}

export default SideBar;