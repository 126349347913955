import logo from './logo.svg';
import './App.css';
import {useState} from 'react';
import Home from './Components/Pages/Home';
import Rewards from './Components/Pages/Rewards';
import Root from './Components/Root';
import AuthRoot from './Components/AuthRoot';
import Login from './Components/Pages/Login';
import Register from './Components/Pages/Register';
import Customer from './Components/Pages/Customer';
import Vehicles from './Components/Pages/Vehicles';
import Context from './Components/Context';
import CusLoader from './cusLoader';
import { createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
  const getLoginData = JSON.parse(localStorage.getItem('logedIn2'));

  //const getLoginData = JSON.parse(localStorage.getItem('logedIn2'));
  const getUserId = JSON.parse(localStorage.getItem('getUserId'));
  const getUserName = JSON.parse(localStorage.getItem('getUserName'));
  const getUserEmail = JSON.parse(localStorage.getItem('getUserEmail'));
  const getUserToken = JSON.parse(localStorage.getItem('getUserToken'));

  const [logedIn, setLogedIn] = useState(getLoginData);
  const [userId, setUserId] = useState(getUserId);
  const [name, setName] = useState(getUserName);
  const [email, setEmail] = useState(getUserEmail);
  const [token, setToken] = useState(getUserToken);
  const router = createBrowserRouter(

      createRoutesFromElements(

        <Route path="/" element=<Root /> >
          <Route index element={<Home />} />
          <Route path="/customers" element={<Customer />} />
          <Route path="/vehicles" element={<Vehicles />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/:id/password/update" element={<Home />} />
        </Route>

      )

  )


const router2 = createBrowserRouter(

      createRoutesFromElements(

        <Route path="/" element=<AuthRoot /> >
          <Route index element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>

      )

  )
  
  if(logedIn)
  {
      return (
          <Context.Provider value={[logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken]}>
            <RouterProvider router={router} />
            <ToastContainer theme="dark" />
          </Context.Provider>
    );
  }
  else{
    return (
          <Context.Provider value={[logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken]}>
            <RouterProvider router={router2} />
            <ToastContainer theme="dark" />
          </Context.Provider>
    );
  }
  
}

export default App;
