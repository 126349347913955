import {useState, useContext, useEffect} from 'react';
import Context from '../Context';
import CusModal from './cusModal';
import OverlaySpinner from '../OverlaySpinner';

const Customer = () => {
	const [cars, setCars] = useState();
	const [cus, setCus] = useState();
	const [cusModal, setCusModal] = useState(false);
	const [load, setLoad] = useState(false);
	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);

	const getCars = async() => {
		setLoad(true);
		const res = await fetch(`https://infinity.te-amo.co.za/api/customers/cars/get?userId=${userId}`);
		const data = await res.json();
		console.log(data);
		setCars(data);
		setLoad(false);
	}

	const getCus = async(e) => {
		const res = await fetch(`https://infinity.te-amo.co.za/api/customer/get?id=${e}`, {
			  method: 'GET',
	          headers: {
	                  'Content-Type': 'application/json',
	                },
		});
		const data = await res.json();

		setCus(data);
		setCusModal(true);
		console.log(cus);
		setLoad(false);
	}

	useEffect(() => {
	  getCars();
	}, []);

	return(<>
			{load?
				<OverlaySpinner />
				:
				''
		  	}
		{cusModal?
		<CusModal cus={cus} setCusModal={setCusModal} />: ''}
		<div class='card-table'>
			<table class="table">
			  <thead>
			    <tr>
			      <th scope="col">#</th>
			      <th scope="col">Make</th>
			      <th scope="col">Model</th>
			      <th scope="col">Registration</th>
			      <th scope="col">Vehicle point</th>
			      <th scope="col">View customer</th>
			    </tr>
			  </thead>
			  <tbody>
			  {cars?.map((car) => (
			  		<tr>
				      <th scope="row">#</th>
				      <td>{car.make}</td>
				      <td>{car.model}</td>
				      <td>{car.registration}</td>
				      <td>{car.points}</td>
				      <td><button className="btn btn-sm btn-danger" onClick={()=>getCus(car.userId)}>View</button></td>
				    </tr>
			  	))
			  }
			  </tbody>
			</table>
		</div>
		</>
	)
}

export default Customer;