import { FaHome, FaUserPlus, FaUsers, FaGift, FaUsersCog } from "react-icons/fa";
import { IconContext } from "react-icons";
const Header = () => {
	return(
		<header className='fixed-top header shadow'>
	       <p className='headerText'>
	       	<IconContext.Provider value={{ size:'2em' }}>
              <div>
                <FaUsersCog />
              </div>
            </IconContext.Provider>
	       </p>
	    </header>
	);
}

export default Header;