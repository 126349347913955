import {useState, useEffect, useContext} from 'react';
import Context from '../Context';
import VehiclesModal from './VehiclesModal';
import TypeOfWahses from './TypeOfWahses';
import RewardModal from './RewardModal';
import OverlaySpinner from '../OverlaySpinner';

const Rewards = () => {
	const [showAddRewards, setShowAddRewards] = useState(false);
	const [rewards, setRewards] = useState();
	const [load, setLoad] = useState(false);
	const [logedIn, setLogedIn, userId, setUserId, name, setName, email, setEmail, token, setToken] = useContext(Context);
	
	const getRewards = async() => {
		setLoad(true);
		const res = await fetch(`https://infinity.te-amo.co.za/api/rewards/get?userId=${userId}`);
		const data = await res.json();

		setRewards(data);
		setLoad(false);
	}

	useEffect(() => {
	  getRewards();
	}, []);

	return(
		<>
			{load?
				<OverlaySpinner />
				:
				''
			}
			<RewardModal showAddRewards={showAddRewards} setShowAddRewards={setShowAddRewards}/>
			<div class='card-table'>
				<button class="btn btn-danger btn-bg" onClick={()=>setShowAddRewards(true)}>Add rewards</button>
			</div>

			<div class='card-table'>
				<table class="table">
				  <thead>
				    <tr>
				      <th scope="col">#</th>
				      <th scope="col">Points</th>
				      <th scope="col">Prize</th>
				    </tr>
				  </thead>
				  <tbody>
				  {rewards?.map((reward) => (
				  		<tr>
					      <th scope="row">#</th>
					      <td>{reward.Points}</td>
					      <td>{reward.Prize}</td>
					    </tr>
				  	))
				  }
				  </tbody>
				</table>
			</div>
		</>
	)
}

export default Rewards;