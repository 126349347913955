import {useState} from 'react'
import {Link} from 'react-router-dom';
import OverlaySpinner from '../OverlaySpinner';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
	
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [pass, setPass] = useState();
	const [loader, setLoader] = useState(false);

	const subReg = async(e) => {
		e.preventDefault();
		setLoader(true);
			const res = await fetch('https://infinity.te-amo.co.za/api/register', {

	      method: 'POST',
	      headers: {
	              'Content-Type': 'application/json',
	            },
	            body: JSON.stringify({'name': name, 'email': email, 'pass': pass}),

	    });


	    if (!res.ok) {
	      throw new Error(`HTTP error! Status: ${res.status}`);
	    }
	    else
	    {
	    	const data = await res.json();

	    	if(data.message == 'Email already exist')
	    	{
	    		setLoader(false);
	    		toast.error('Email already exists', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
	    	}
	    	else{
	    		setLoader(false);
		    	setName('');
		    	setEmail('');
		    	setPass('');

		    	toast.success('account created, you can sign in', {
		          position: "top-right",
		          autoClose: 5000,
		          hideProgressBar: false,
		          closeOnClick: true,
		          pauseOnHover: true,
		          draggable: true,
		          progress: undefined,
		          theme: "dark",
		        });
	    	}
	    	
	    }
	}

	return(
		<>{loader?
              <OverlaySpinner />
              :
              ''
            }
		<div class='auth-form'>
			<form onSubmit={(e)=>subReg(e)}>
			  <div class="form-group">
			    <label for="exampleInputEmail1">Car wash name</label>
			    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter name" value={name} onChange={(e)=>setName(e.target.value)} />
			  </div><br />
			  <div class="form-group">
			    <label for="exampleInputEmail1">Email address</label>
			    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value={email} onChange={(e)=>setEmail(e.target.value)} />
			  </div><br />
			  <div class="form-group">
			    <label for="exampleInputPassword1">Password</label>
			    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" value={pass} onChange={(e)=>setPass(e.target.value)} />
			  </div><br />
			
			  <button type="submit" class="btn btn-dark">Submit</button><br />
			  <small>Already have an account?<Link to='/'>Login</Link></small>
			</form>
		</div>
		</>
	)
}

export default Register;